import AcreageReportingReq from '../AcreageReportingListOfCalls/AcreageReportingReq'
import SectionWrapperApiCalls from '../SectionWrapperApiCalls'
import style from '../style.module.scss'

const HistoricalDataListOfCalls = () => {
  return (
    <>
      <div className={style.getTokenContainer}>
        <SectionWrapperApiCalls linkName={'year_acreage_reporting'}>
          <h2 className="toclink" data-link="api_calls/year_acreage_reporting">
            /year/acreage_reporting
          </h2>
          <p>Returns all acreage reporting data in a single response.</p>
          <p>
            Each object in the response represents a single CLU and includes information about the CLU’s
            planting date, types of crops identified, and the number of acres planted and operated. Each
            object also includes metadata associated with the particular CLU (e.g., farm ID, state code,
            etc.).
          </p>
          <p>
            For historical data access, see the Historical Data section where you can specify the year
            in the URL path.
          </p>
          <AcreageReportingReq isHistoricalYear />
        </SectionWrapperApiCalls>

      </div>
    </>
  )
}

export default HistoricalDataListOfCalls
