// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_codeSelectContainer__uNKZu {
  position: absolute;
  top: 0;
  padding: 20px;
  padding-top: 0;
  left: 700px;
  flex: 1 1;
}
.style_codeSelectContainer__uNKZu h3 {
  margin-bottom: 32px;
}
.style_codeSelectContainer__uNKZu > div:first-child {
  max-width: 100%;
}

@media (max-width: 1400px) {
  .style_codeSelectContainer__uNKZu {
    left: 500px;
  }
}
@media (max-width: 1300px) {
  .style_codeSelectContainer__uNKZu {
    position: initial;
    padding-left: 0;
    margin-top: 25px;
    padding-right: 0;
    left: 0;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/ApiCalls/CodeSnippet/style.module.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,MAAA;EACA,aAAA;EACA,cAAA;EACA,WAAA;EACA,SAAA;AACJ;AAAI;EACI,mBAAA;AAER;AAAI;EACI,eAAA;AAER;;AAEA;EACI;IACI,WAAA;EACN;AACF;AAEA;EACI;IACI,iBAAA;IACA,eAAA;IACA,gBAAA;IACA,gBAAA;IACA,OAAA;EAAN;AACF","sourcesContent":[".codeSelectContainer {\n    position: absolute;\n    top: 0;\n    padding: 20px;\n    padding-top: 0;\n    left: 700px;\n    flex: 1;\n    h3 {\n        margin-bottom: 32px;\n    }\n    > div:first-child {\n        max-width: 100%;\n    }\n}\n\n@media (max-width: 1400px) {\n    .codeSelectContainer {\n        left: 500px;\n    }\n}\n\n@media (max-width: 1300px) {\n    .codeSelectContainer {\n        position: initial;\n        padding-left: 0;\n        margin-top: 25px;\n        padding-right: 0;\n        left: 0;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"codeSelectContainer": `style_codeSelectContainer__uNKZu`
};
export default ___CSS_LOADER_EXPORT___;
