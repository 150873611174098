// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_codePreviewListContainer__72rgx {
  display: flex;
}
.style_codePreviewListContainer__72rgx > * {
  font-family: "NunitoSans-Regular";
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  width: 100%;
  border: 1px solid #d2dae6;
  padding: 7px 12px;
  color: #d2dae6;
}
.style_codePreviewListContainer__72rgx > *:hover {
  color: #ffffff;
  opacity: 0.7;
  background-color: #00C6BB;
}
.style_codePreviewListContainer__72rgx > *:first-child {
  border-radius: 8px 0px 0px 8px;
  border-right: none;
}
.style_codePreviewListContainer__72rgx > *:last-child {
  border-radius: 0px 8px 8px 0px;
  border-left: none;
}

.style_active__cLK7E {
  color: #ffffff !important;
  background-color: #00C6BB;
}`, "",{"version":3,"sources":["webpack://./src/components/ApiCalls/CodeSnippet/CodePreviewList/style.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;AACJ;AAAI;EACI,iCAAA;EACA,eAAA;EACA,gBAAA;EACA,kBAAA;EACA,WAAA;EACA,yBAAA;EACA,iBAAA;EACA,cAAA;AAER;AADQ;EACI,cAAA;EACA,YAAA;EACA,yBAAA;AAGZ;AAAI;EACI,8BAAA;EACA,kBAAA;AAER;AAAI;EACI,8BAAA;EACA,iBAAA;AAER;;AAEA;EACI,yBAAA;EACA,yBAAA;AACJ","sourcesContent":[".codePreviewListContainer {\n    display: flex;\n    > * {\n        font-family: \"NunitoSans-Regular\";\n        font-size: 14px;\n        font-weight: 500;\n        text-align: center;\n        width: 100%;\n        border: 1px solid #d2dae6;\n        padding: 7px 12px;\n        color: #d2dae6;\n        &:hover {\n            color: #ffffff;\n            opacity: 0.7;\n            background-color: #00C6BB;\n        }\n    }\n    > *:first-child {\n        border-radius: 8px 0px 0px 8px;\n        border-right: none;\n    }\n    > *:last-child {\n        border-radius: 0px 8px 8px 0px;\n        border-left: none;\n    }\n}\n\n.active {\n    color: #ffffff !important;\n    background-color: #00C6BB;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"codePreviewListContainer": `style_codePreviewListContainer__72rgx`,
	"active": `style_active__cLK7E`
};
export default ___CSS_LOADER_EXPORT___;
