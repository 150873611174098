// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_sectionWrapper__lHkUp {
  padding-top: 25px;
  padding-bottom: 25px;
  border-bottom: 1px solid #cad3e2;
  margin-left: 37px;
  margin-right: 25px;
}
.style_sectionWrapper__lHkUp:last-child {
  border-bottom: none;
}

@media (min-width: 1299px) {
  .style_sectionWrapper__lHkUp {
    width: 458px;
  }
}
@media (min-width: 1400px) {
  .style_sectionWrapper__lHkUp {
    width: 660px;
  }
}
@media (max-width: 1300px) {
  .style_sectionWrapper__lHkUp {
    flex: 1 1;
    width: calc(100vw - 472px);
    padding-right: 0;
    margin-right: 0;
  }
}
@media (max-width: 900px) {
  .style_sectionWrapper__lHkUp {
    flex: 1 1;
    width: calc(100vw - 93px);
    margin-right: 0;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/SectionWrapper/style.module.scss"],"names":[],"mappings":"AAAA;EACI,iBAAA;EACA,oBAAA;EACA,gCAAA;EACA,iBAAA;EACA,kBAAA;AACJ;AAAI;EACI,mBAAA;AAER;;AAEA;EACI;IACI,YAAA;EACN;AACF;AACA;EACI;IACI,YAAA;EACN;AACF;AAEA;EACI;IACI,SAAA;IACA,0BAAA;IACA,gBAAA;IACA,eAAA;EAAN;AACF;AAGA;EACI;IACI,SAAA;IACA,yBAAA;IACA,eAAA;EADN;AACF","sourcesContent":[".sectionWrapper {\n    padding-top: 25px;\n    padding-bottom: 25px;\n    border-bottom: 1px solid #cad3e2;\n    margin-left: 37px;\n    margin-right: 25px;\n    &:last-child {\n        border-bottom: none;\n    }\n}\n\n@media (min-width: 1299px) {\n    .sectionWrapper {\n        width: 458px;\n    }\n}\n@media (min-width: 1400px) {\n    .sectionWrapper {\n        width: 660px;\n    }\n}\n\n@media (max-width: 1300px) {\n    .sectionWrapper {\n        flex: 1;\n        width: calc(100vw - 472px);\n        padding-right: 0;\n        margin-right: 0;\n    }\n}\n\n@media (max-width: 900px) {\n    .sectionWrapper {\n        flex: 1;\n        width: calc(100vw - 93px);\n        margin-right: 0;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sectionWrapper": `style_sectionWrapper__lHkUp`
};
export default ___CSS_LOADER_EXPORT___;
