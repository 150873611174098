// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.string {
  color: rgb(102, 153, 0);
}

.number {
  color: darkorange;
}

.boolean {
  color: blue;
}

.null {
  color: magenta;
}

.key {
  color: rgb(153, 0, 85);
}

.line-number {
  pointer-events: none;
  -webkit-user-select: none;
          user-select: none;
}`, "",{"version":3,"sources":["webpack://./src/components/ApiCalls/CodeSnippet/ResponseDisplay/Json.snippet.style.scss"],"names":[],"mappings":"AAAA;EAAU,uBAAA;AAEV;;AADA;EAAU,iBAAA;AAKV;;AAJA;EAAW,WAAA;AAQX;;AAPA;EAAQ,cAAA;AAWR;;AAVA;EAAO,sBAAA;AAcP;;AAbA;EACI,oBAAA;EACA,yBAAA;UAAA,iBAAA;AAgBJ","sourcesContent":[".string { color: rgb(102, 153, 0); }\n.number { color: darkorange; }\n.boolean { color: blue; }\n.null { color: magenta; }\n.key { color: rgb(153, 0, 85);; }\n.line-number{\n    pointer-events: none;\n    user-select: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
