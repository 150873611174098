

const StartFrom = ({ filters, setFilters }) => {
  const onChange = ({ name, value }) => {
    setFilters({
      ...filters,
      [name]: value,
    })
  }

  return (
    <div>
     
      <input
        type="text"
        name="start_from"
        placeholder="start_from"
        value={filters.start_from}
        onChange={({ target }) => onChange(target)}
      />
    </div>
  )
}

export default StartFrom
