// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_paginationContainer__sQlKp {
  font-family: "NunitoSans-Regular";
  font-size: 0.875rem;
}
.style_paginationContainer__sQlKp h1 {
  font-family: "NunitoSans-Bold";
  color: #415575;
  font-size: 1.125rem;
  margin-bottom: 25px;
}
.style_paginationContainer__sQlKp h3 {
  color: #182F4E;
  line-height: 150%;
}
.style_paginationContainer__sQlKp h2 {
  font-family: "NunitoSans-Bold";
  color: #415575;
  margin-top: 25px;
  margin-bottom: 25px;
}
.style_paginationContainer__sQlKp span {
  color: #00C6BB;
}

.style_paginationReqExample__f0Hcz > * {
  margin-bottom: 25px;
}
.style_paginationReqExample__f0Hcz > * h3 {
  background-color: #F0FDFC;
  color: #00C6BB;
  width: fit-content;
  margin-bottom: 25px;
}
.style_paginationReqExample__f0Hcz > * h4 {
  font-family: "NunitoSans-SemiBold";
  font-size: 0.875rem;
}`, "",{"version":3,"sources":["webpack://./src/components/GettingStarted/Pagination/style.module.scss"],"names":[],"mappings":"AACA;EACI,iCAAA;EACA,mBAAA;AAAJ;AACI;EACI,8BAAA;EACA,cAAA;EACA,mBAAA;EACA,mBAAA;AACR;AACI;EACI,cAAA;EACA,iBAAA;AACR;AACI;EACI,8BAAA;EACA,cAAA;EACA,gBAAA;EACA,mBAAA;AACR;AACI;EACI,cAAA;AACR;;AAKI;EACI,mBAAA;AAFR;AAGQ;EACI,yBAAA;EACA,cAAA;EACA,kBAAA;EACA,mBAAA;AADZ;AAGQ;EACI,kCAAA;EACA,mBAAA;AADZ","sourcesContent":["\n.paginationContainer{\n    font-family: 'NunitoSans-Regular';\n    font-size: 0.875rem;\n    h1{\n        font-family: 'NunitoSans-Bold';\n        color: #415575;\n        font-size: 1.125rem;\n        margin-bottom: 25px;\n    }\n    h3{\n        color: #182F4E;\n        line-height: 150%;\n    }\n    h2{\n        font-family: 'NunitoSans-Bold';\n        color: #415575;\n        margin-top: 25px;\n        margin-bottom: 25px;\n    }\n    span{\n        color: #00C6BB;\n    }\n\n}\n\n.paginationReqExample{\n    >*{\n        margin-bottom: 25px;\n        h3{\n            background-color: #F0FDFC;\n            color: #00C6BB;\n            width: fit-content;\n            margin-bottom: 25px;\n        }\n        h4{\n            font-family: 'NunitoSans-SemiBold';\n            font-size: 0.875rem;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"paginationContainer": `style_paginationContainer__sQlKp`,
	"paginationReqExample": `style_paginationReqExample__f0Hcz`
};
export default ___CSS_LOADER_EXPORT___;
