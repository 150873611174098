// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_sideBar__XmqmE {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 33px;
  color: #fff;
  overflow-y: auto;
  width: 380px;
  height: 100%;
  z-index: 2;
}
.style_sideBar__XmqmE::-webkit-scrollbar {
  border-radius: 30px;
  width: 6px;
  background-color: rgb(219, 219, 219);
}
.style_sideBar__XmqmE::-webkit-scrollbar-thumb {
  border-radius: 16px;
  background-color: #708097;
}

.style_sideBarClosed__pGUnm {
  position: relative;
  left: -100%;
  animation: style_closedSlideOut__Jm6OU 0.3s linear;
}

.style_sideBarOpen__lI2n6 {
  left: 0;
  animation: style_closedSlideIn__d2J6Y 0.3s linear;
}

@media (max-width: 900px) {
  .style_sideBar__XmqmE {
    position: absolute;
    z-index: 2;
    background-color: #FFFFFF;
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0);
  }
}
@keyframes style_closedSlideOut__Jm6OU {
  from {
    left: 0;
    opacity: 1;
  }
  to {
    left: -100%;
    opacity: 0;
  }
}
@keyframes style_closedSlideIn__d2J6Y {
  from {
    left: -100%;
    opacity: 0;
  }
  to {
    left: 0;
    opacity: 1;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/SideBar/style.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,8BAAA;EACA,iBAAA;EACA,WAAA;EACA,gBAAA;EACA,YAAA;EAWA,YAAA;EACA,UAAA;AATF;AAFE;EACE,mBAAA;EACA,UAAA;EACA,oCAAA;AAIJ;AADE;EACE,mBAAA;EACA,yBAAA;AAGJ;;AAGA;EACE,kBAAA;EACA,WAAA;EACA,kDAAA;AAAF;;AAGA;EACE,OAAA;EACA,iDAAA;AAAF;;AAGA;EACE;IACE,kBAAA;IACA,UAAA;IACA,yBAAA;IACA,sCAAA;EAAF;AACF;AAGA;EACE;IACE,OAAA;IACA,UAAA;EADF;EAGA;IACA,WAAA;IACE,UAAA;EADF;AACF;AAIA;EACE;IACE,WAAA;IACA,UAAA;EAFF;EAIA;IACE,OAAA;IACA,UAAA;EAFF;AACF","sourcesContent":[".sideBar {\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n  padding-top: 33px;\n  color: #fff;\n  overflow-y: auto;\n  width: 380px;\n  &::-webkit-scrollbar {\n    border-radius: 30px;\n    width: 6px;\n    background-color: rgb(219, 219, 219);\n  }\n\n  &::-webkit-scrollbar-thumb {\n    border-radius: 16px;\n    background-color: #708097;\n  }\n  height: 100%;\n  z-index: 2;\n}\n\n.sideBarClosed {\n  position: relative;\n  left: -100%;\n  animation: closedSlideOut 0.3s linear;\n}\n\n.sideBarOpen {\n  left: 0;\n  animation: closedSlideIn 0.3s linear;\n}\n\n@media (max-width:900px) {\n  .sideBar{\n    position: absolute;\n    z-index: 2;\n    background-color: #FFFFFF;\n    box-shadow: 0 0 2px 0 #0000;\n  }\n}\n\n@keyframes closedSlideOut {\n  from {\n    left: 0;\n    opacity: 1;\n  }\n  to {\n  left: -100%;\n    opacity: 0;\n  }\n}\n\n@keyframes closedSlideIn {\n  from {\n    left: -100%;\n    opacity: 0;\n  }\n  to {\n    left: 0;\n    opacity: 1;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sideBar": `style_sideBar__XmqmE`,
	"sideBarClosed": `style_sideBarClosed__pGUnm`,
	"closedSlideOut": `style_closedSlideOut__Jm6OU`,
	"sideBarOpen": `style_sideBarOpen__lI2n6`,
	"closedSlideIn": `style_closedSlideIn__d2J6Y`
};
export default ___CSS_LOADER_EXPORT___;
