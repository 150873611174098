// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_noteDocumenationContainer__DW8bp {
  position: relative;
  padding-block: 20px;
  padding-inline: 30px;
  margin-top: 25px;
  border-radius: 8px;
  font-family: "NunitoSans-Regular";
  font-size: 0.875rem;
  color: #FFFFFF;
  line-height: 150%;
  background-color: #00c6bb;
  box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.05);
}

.style_closeBtn__83JMf {
  position: absolute;
  right: 0;
  top: 0;
  margin: 5px;
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/components/NoteDocumention/style.module.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,mBAAA;EACA,oBAAA;EACA,gBAAA;EACA,kBAAA;EACA,iCAAA;EACA,mBAAA;EACA,cAAA;EACA,iBAAA;EACA,yBAAA;EACA,+CAAA;AACJ;;AAEA;EACI,kBAAA;EACA,QAAA;EACA,MAAA;EACA,WAAA;EACA,eAAA;AACJ","sourcesContent":[".noteDocumenationContainer{\n    position: relative;\n    padding-block:20px;\n    padding-inline: 30px;\n    margin-top: 25px;\n    border-radius: 8px;\n    font-family: 'NunitoSans-Regular';\n    font-size: 0.875rem;\n    color: #FFFFFF;\n    line-height: 150%;\n    background-color: #00c6bb;\n    box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.05);\n}\n\n.closeBtn{\n    position: absolute;\n    right: 0;\n    top: 0;\n    margin: 5px;\n    cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"noteDocumenationContainer": `style_noteDocumenationContainer__DW8bp`,
	"closeBtn": `style_closeBtn__83JMf`
};
export default ___CSS_LOADER_EXPORT___;
