import { useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getAcreageReportingForecast } from '../../../../store/actions/api.calls.actions'
import ApiRequestForm from '../../ApiRequestForm'
import CodeSnippet from '../../CodeSnippet'
import CalcMarginBottom from '../../hooks/CalcMarginBottom'
import ParameterList from '../../ParameterList'
import ResTypeCodeDisplay from '../../ResTypeCodeDisplay'
import resSampleAcreageReportingForecast from '../../samples/resSampleAcreageReportingForecast'

import style from '../../style.module.scss'
import filtersForm from '../../utils/filtersForm'

const customOptions = ["--","usda_clu_id","usda_state_code", "usda_county_code", "crop_forecast.crop_code"]


const AcreageReportingForecastReq = ({isHistoricalYear=false}) => {

  const forecastFilters = {
    year: isHistoricalYear ? '2024' : null,
    filter_by: '',
    filter_value: '',
    sort_by: 'usda_state_code',
    asc: 'true',
    start_from: '',
    limit: '5',
  }

  const [resTypeCodeMarginBottom, setResTypeCodeMarginBottom] = useState(0)
  const [activeCodePreview, setActiveCodePreview] = useState('200')
  const [paramsMarginBottom, setParamsMarginBottom] = useState(0)
  const [codeConainerMarginBottom, setCodeConainerMarginBottom] = useState(0)
  const [filters, setFilters] = useState(filtersForm(forecastFilters));
  const route = isHistoricalYear ? `${filters.year}/acreage_reporting/forecast` : 'acreage_reporting/forecast';
  const customFilters = isHistoricalYear ? filters : {...filters, year: null};

  const { isAuthorized, authorizedToken, screenSize } = useSelector((state) => state.user)
  const { acreageReportingForecast } = useSelector((state) => state.api)

  const dispatch = useDispatch()

  const codeRef = useRef()
  const parametersReqRef = useRef()
  const resTypeCodeRef = useRef()
  const resTypeRef = useRef()

  const isScreenSizeParmeterMarginBottomDesktop = useMemo(() => {
    return screenSize.mode === 'Desktop' ? { marginBottom: `${paramsMarginBottom}px` } : {}
  }, [screenSize, paramsMarginBottom])

  const isScreenSizeDesktop = useMemo(() => {
    return screenSize.mode === 'Desktop' ? { marginBottom: `${resTypeCodeMarginBottom}px` } : {}
  }, [screenSize, resTypeCodeMarginBottom])

  const res = useMemo(() => {
    if (!activeCodePreview) return ''
    if (activeCodePreview === '200') {
      return resSampleAcreageReportingForecast['200']
    } else if (activeCodePreview === '401') {
      return resSampleAcreageReportingForecast['401']
    } else if (activeCodePreview === '400') {
      return resSampleAcreageReportingForecast['400']
    } else {
      return resSampleAcreageReportingForecast['404']
    }
  }, [activeCodePreview])

  CalcMarginBottom({
    setParamsMarginBottom,
    response: acreageReportingForecast,
    resTypeRef,
    resTypeCodeRef,
    setResTypeCodeMarginBottom,
    setCodeConainerMarginBottom,
    codeRef,
    parametersReqRef,
  })

  const onExecute = async (filters) => {
    await dispatch(getAcreageReportingForecast(filters, authorizedToken))
  }

  return (
    <div className={style.parameterContainer}>
      <div ref={parametersReqRef} style={isScreenSizeParmeterMarginBottomDesktop}>
        <h3>Parameters</h3>
        <ParameterList>
          {isHistoricalYear && (
            <div className={style.sessionLengthContainer}>
              <div>
                <h4>year</h4>
                <h5>string</h5>
              </div>
              <div>
                <h6>The year for which to retrieve acreage reporting data</h6>
                <p>
                  Specify the year in YYYY format (e.g., 2023, 2024). This parameter is required and must be included in the URL path.
                </p>
              </div>
            </div>
          )}
          <div className={style.sessionLengthContainer}>
            <div>
              <h4>filter_by</h4>
              <h5>string</h5>
            </div>
            <div>
              <h6>Results can be filtered by the following values:</h6>
              <p>
                usda_clu_id, usda_state_code, usda_county_code, crop_forecast.crop_code.
              </p>
            </div>
          </div>
          <div>
            <div>
              <h4>filter_value</h4>
              <h5>string</h5>
            </div>
            <div>
              <h6>
                Results can be filtered by a specific value which is related to the filter_by value selected
                above.
              </h6>
              <p>
                <span>There are several such examples:</span> If usda_state_code was selected as a filter_by
                value, then to return results pertaining to Arizona only you would enter '04' (Arizona’s state
                code) here.
              </p>
              <h6 className={style.notesTitle}>Notes</h6>
              <ul className={style.notesListContainer}>
                <li>
                  <div></div>{' '}
                  <h6>If a filter_by value is selected, leaving this blank will return an error.</h6>{' '}
                </li>
                <li>
                  <div></div>
                  <h6>If no filter_by value is selected, entering a value here will return an error.</h6>{' '}
                </li>
              </ul>
            </div>
          </div>
          <div>
            <div>
              <h4>sort_by</h4>
              <h5>string</h5>
            </div>
            <div>
              <h6>Results can be sorted by the following values:</h6>
              <p>usda_clu_id, usda_state_code, usda_county_code, crop_forecast.crop_code.</p>
              <h6 className={style.notesTitle}>Notes</h6>
              <ul className={style.notesListContainer}>
                <li>
                  <div></div>{' '}
                  <h6>
                    Choosing farm_tract_field will perform a sort by three values in the following order:
                    farm_number , then tract_number, then field_number.
                  </h6>{' '}
                </li>
                <li>
                  <div></div>
                  <h6>
                    Choosing state_county will perform a sort by two values in the following order:
                    usda_state_code, then usda_county_code.
                  </h6>{' '}
                </li>
              </ul>
            </div>
          </div>
          <div>
            <div>
              <h4>asc</h4>
              <h5>string</h5>
            </div>
            <div>
              <h6>
                The direction by which results are sorted. If true, results are sorted in ascending order; if
                false, they are sorted in descending order.
              </h6>
              <p>true, false</p>
            </div>
          </div>
          <div>
            <div>
              <h4>start_from</h4>
              <h5>string</h5>
            </div>
            <div>
              <h6>
                Results are returned starting from the value entered here. For example: entering '5' would
                return results starting from the sixth one.
              </h6>
            </div>
          </div>
          <div>
            <div>
              <h4>limit</h4>
              <h5>string</h5>
            </div>
            <div>
              <h6>The number of results to return.</h6>
            </div>
          </div>
        </ParameterList>
      </div>
      <CodeSnippet
        route={route}
        marginBottom={codeConainerMarginBottom}
        filters={{ ...customFilters }}
        resCodeDisplay={
          <ResTypeCodeDisplay
            resTypeCodeRef={resTypeCodeRef}
            setActiveCodePreview={setActiveCodePreview}
            activeCodePreview={activeCodePreview}
            res={res}
          />
        }
        title={'Request example'}
        codeRef={codeRef}
        response={acreageReportingForecast}
      >
        <ApiRequestForm
          route={route}
          customOptions={customOptions}
          onExecute={onExecute}
          isAuthorized={isAuthorized}
          filters={customFilters}
          setFilters={setFilters}
          isHistoricalYear={isHistoricalYear}
        />
      </CodeSnippet>
      <div className={style.resTypeContainer} ref={resTypeRef} style={isScreenSizeDesktop}>
        <div>
          <h3>Responses</h3>
        </div>
        <ParameterList>
          <div>
            <div>
              <h4>200</h4>
            </div>
            <div>
              <h6 className={style.resType}>Success</h6>
            </div>
          </div>

          <div>
            <div>
              <h4>400</h4>
            </div>
            <div>
              <h6 className={style.resType}>Bad request</h6>
            </div>
          </div>
          <div>
            <div>
              <h4>401</h4>
            </div>
            <div>
              <h6 className={style.resType}>Unauthorized</h6>
            </div>
          </div>
          <div>
            <div>
              <h4>404</h4>
            </div>
            <div>
              <h6 className={style.resType}>Not found</h6>
            </div>
          </div>
        </ParameterList>
      </div>
    </div>
  )
}

export default AcreageReportingForecastReq
