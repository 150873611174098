// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_codeDisplay__X81O6 {
  position: relative;
  font-family: "SourceCodeProRegular";
  width: 100%;
  margin-top: 16px;
  font-weight: 500;
  border-radius: 16px;
  border: 1px solid #cad3e2;
  font-size: 16px;
  line-height: 150%;
  color: #617ca7;
}
.style_codeDisplay__X81O6 pre {
  width: 100%;
  height: 100%;
  border-radius: 16px;
  border-bottom: none;
  margin-top: 0 !important;
  margin-bottom: 0.5px !important;
  font-size: 0.875rem !important;
}
.style_codeDisplay__X81O6 pre::-webkit-scrollbar {
  border-radius: 0 0 16px 16px;
  height: 10px;
  background-color: rgb(219, 219, 219);
}
.style_codeDisplay__X81O6 pre::-webkit-scrollbar-thumb {
  border-radius: 0 0 16px 16px;
  background-color: #708097;
}`, "",{"version":3,"sources":["webpack://./src/components/ApiCalls/CodeSnippet/CodeDisplay/style.module.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,mCAAA;EACA,WAAA;EACA,gBAAA;EACA,gBAAA;EACA,mBAAA;EACA,yBAAA;EAEA,eAAA;EACA,iBAAA;EACA,cAAA;AAAJ;AAEI;EACI,WAAA;EACA,YAAA;EACA,mBAAA;EACA,mBAAA;EACA,wBAAA;EACA,+BAAA;EACA,8BAAA;AAAR;AACQ;EACI,4BAAA;EACA,YAAA;EACA,oCAAA;AACZ;AAEQ;EACI,4BAAA;EAEA,yBAAA;AADZ","sourcesContent":[".codeDisplay {\n    position: relative;\n    font-family: \"SourceCodeProRegular\";\n    width: 100%;\n    margin-top: 16px;\n    font-weight: 500;\n    border-radius: 16px;\n    border: 1px solid #cad3e2;\n\n    font-size: 16px;\n    line-height: 150%;\n    color: #617ca7;\n\n    pre {\n        width: 100%;\n        height: 100%;\n        border-radius: 16px;\n        border-bottom: none;\n        margin-top: 0 !important;\n        margin-bottom: 0.5px !important;\n        font-size: 0.875rem !important;\n        &::-webkit-scrollbar {\n            border-radius: 0 0 16px 16px;\n            height: 10px;\n            background-color: rgb(219, 219, 219);\n        }\n\n        &::-webkit-scrollbar-thumb {\n            border-radius: 0 0 16px 16px;\n\n            background-color: #708097;\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"codeDisplay": `style_codeDisplay__X81O6`
};
export default ___CSS_LOADER_EXPORT___;
