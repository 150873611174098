// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_responseDisplay__h-35A {
  position: relative;
  overflow-y: auto;
  font-family: "SourceCodeProRegular";
  height: 400px;
  width: 100%;
  margin-top: 16px;
  border-radius: 16px 16px 8px 8px;
  border: 1px solid #cad3e2;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  color: #617ca7;
  overflow-y: hidden;
}
.style_responseDisplay__h-35A pre {
  width: 100%;
  height: 100%;
  background-color: rgb(245, 242, 240);
  border-radius: 16px 16px 8px 8px;
  overflow: auto;
  padding: 10px;
  text-align: left;
  white-space: pre;
  word-spacing: normal;
  word-break: normal;
  overflow-wrap: normal;
  margin: 0;
  margin-bottom: 1px !important;
  font-size: 0.875rem !important;
}
.style_responseDisplay__h-35A pre::-webkit-scrollbar {
  border-radius: 0 16px 16px 0;
  height: 10px;
  width: 10px;
  background-color: rgb(219, 219, 219);
}
.style_responseDisplay__h-35A pre::-webkit-scrollbar-thumb {
  border-radius: 0 16px 16px 0;
  background-color: #708097;
}
.style_responseDisplay__h-35A pre::-webkit-scrollbar:horizontal {
  border-radius: 0 0 16px 16px;
  height: 10px;
  width: 10px;
  background-color: rgb(219, 219, 219);
}
.style_responseDisplay__h-35A pre::-webkit-scrollbar-thumb:horizontal {
  border-radius: 0 0 16px 16px;
  background-color: #708097;
}`, "",{"version":3,"sources":["webpack://./src/components/ApiCalls/CodeSnippet/ResponseDisplay/style.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,gBAAA;EACA,mCAAA;EACA,aAAA;EACA,WAAA;EACA,gBAAA;EACA,gCAAA;EACA,yBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;EACA,kBAAA;AACF;AACE;EACE,WAAA;EACA,YAAA;EACA,oCAAA;EACA,gCAAA;EACA,cAAA;EACA,aAAA;EACA,gBAAA;EACA,gBAAA;EACA,oBAAA;EACA,kBAAA;EACA,qBAAA;EACA,SAAA;EACA,6BAAA;EACA,8BAAA;AACJ;AAAI;EACE,4BAAA;EAEA,YAAA;EACA,WAAA;EACA,oCAAA;AACN;AAEI;EACE,4BAAA;EACA,yBAAA;AAAN;AAGI;EACE,4BAAA;EAEA,YAAA;EACA,WAAA;EACA,oCAAA;AAFN;AAKI;EACE,4BAAA;EACA,yBAAA;AAHN","sourcesContent":[".responseDisplay {\n  position: relative;\n  overflow-y: auto;\n  font-family: \"SourceCodeProRegular\";\n  height: 400px;\n  width: 100%;\n  margin-top: 16px;\n  border-radius: 16px 16px 8px 8px;\n  border: 1px solid #cad3e2;\n  font-weight: 500;\n  font-size: 16px;\n  line-height: 150%;\n  color: #617ca7;\n  overflow-y: hidden;\n\n  pre {\n    width: 100%;\n    height: 100%;\n    background-color: rgb(245, 242, 240);\n    border-radius: 16px 16px 8px 8px;\n    overflow: auto;\n    padding: 10px;\n    text-align: left;\n    white-space: pre;\n    word-spacing: normal;\n    word-break: normal;\n    overflow-wrap: normal;\n    margin: 0;\n    margin-bottom: 1px !important;\n    font-size: 0.875rem !important;\n    &::-webkit-scrollbar {\n      border-radius: 0 16px 16px 0;\n\n      height: 10px;\n      width: 10px;\n      background-color: rgb(219, 219, 219);\n    }\n\n    &::-webkit-scrollbar-thumb {\n      border-radius: 0 16px 16px 0;\n      background-color: #708097;\n    }\n\n    &::-webkit-scrollbar:horizontal {\n      border-radius: 0 0 16px 16px;\n\n      height: 10px;\n      width: 10px;\n      background-color: rgb(219, 219, 219);\n    }\n\n    &::-webkit-scrollbar-thumb:horizontal {\n      border-radius: 0 0 16px 16px;\n      background-color: #708097;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"responseDisplay": `style_responseDisplay__h-35A`
};
export default ___CSS_LOADER_EXPORT___;
