// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_searchLoader__5ZPZv {
  display: flex;
  margin-right: 5px;
  width: 15px !important;
  animation: style_fadeIn__0JU1C 0.3s ease-in alternate;
}

.style_searchLoaderSpinner__TzxB5 {
  width: 15px;
  animation: style_searchLoaderSpin__dir6\\+ infinite 1s linear;
}

@keyframes style_fadeIn__0JU1C {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes style_searchLoaderSpin__dir6\\+ {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}`, "",{"version":3,"sources":["webpack://./src/components/layout/AppShell/SearchLoader/style.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,iBAAA;EACA,sBAAA;EACA,qDAAA;AACJ;;AAEA;EACI,WAAA;EACA,4DAAA;AACJ;;AAEA;EACI;IACI,UAAA;EACN;EACE;IACI,UAAA;EACN;AACF;AAEA;EACI;IACI,uBAAA;EAAN;EAEE;IACI,yBAAA;EAAN;AACF","sourcesContent":[".searchLoader {\n    display: flex;\n    margin-right: 5px;\n    width: 15px !important;\n    animation: fadeIn 0.3s ease-in alternate;\n}\n\n.searchLoaderSpinner {\n    width: 15px;\n    animation: searchLoaderSpin infinite 1s linear;\n}\n\n@keyframes fadeIn {\n    from {\n        opacity: 0;\n    }\n    to {\n        opacity: 1;\n    }\n}\n\n@keyframes searchLoaderSpin {\n    from {\n        transform: rotate(0deg);\n    }\n    to {\n        transform: rotate(360deg);\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"searchLoader": `style_searchLoader__5ZPZv`,
	"fadeIn": `style_fadeIn__0JU1C`,
	"searchLoaderSpinner": `style_searchLoaderSpinner__TzxB5`,
	"searchLoaderSpin": `style_searchLoaderSpin__dir6+`
};
export default ___CSS_LOADER_EXPORT___;
