// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_docContainer__BvoUV {
  position: relative;
  display: flex;
  overflow-y: auto;
  overflow-x: hidden;
  flex-direction: column;
  border-left: 1px solid #d5deec;
  height: calc(100vh - 57px);
  padding-right: 37px;
  flex: 1 1;
  padding-bottom: 33px;
}

.style_docContainerHeadline__aZyWL,
.style_docContainerHeadlineDoc__p1o5Z {
  padding-top: 49px;
  font-family: "NunitoSans-ExtraBold";
  font-size: 1.5rem;
  line-height: 135%;
  color: #415575;
}

.style_docContainerHeadlineDoc__p1o5Z {
  margin-left: 37px;
}

.style_docContainerBorder__FWDEi {
  position: fixed;
  left: 1100px;
  top: 53px;
  bottom: 0;
  border-right: 1px solid #d5deec;
}

.style_noteSectionWrapper__P\\+YVI {
  border-bottom: none;
  padding-block: 0;
}

.style_sandboxTitle__NFa3l,
.style_sandboxSubtitle__da1rL {
  font-family: "NunitoSans-Regular";
  font-size: 0.875rem;
  color: #202a3b;
  line-height: 150%;
}

.style_sandboxTitle__NFa3l {
  margin-top: 16px;
}

.style_sandboxSubtitle__da1rL {
  margin-top: 12px;
}

@media (max-width: 1400px) {
  .style_docContainerBorder__FWDEi {
    left: 900px;
  }
}
@media (max-width: 900px) {
  .style_docContainer__BvoUV {
    width: 100vw;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/Doc/style.module.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,aAAA;EACA,gBAAA;EACA,kBAAA;EACA,sBAAA;EACA,8BAAA;EACA,0BAAA;EACA,mBAAA;EACA,SAAA;EACA,oBAAA;AACJ;;AAEA;;EAEI,iBAAA;EACA,mCAAA;EACA,iBAAA;EACA,iBAAA;EACA,cAAA;AACJ;;AAEA;EACI,iBAAA;AACJ;;AAEA;EACI,eAAA;EACA,YAAA;EACA,SAAA;EACA,SAAA;EACA,+BAAA;AACJ;;AAEA;EACI,mBAAA;EACA,gBAAA;AACJ;;AAEA;;EAEI,iCAAA;EACA,mBAAA;EACA,cAAA;EACA,iBAAA;AACJ;;AAEA;EACI,gBAAA;AACJ;;AAEA;EACI,gBAAA;AACJ;;AAEA;EACI;IACI,WAAA;EACN;AACF;AAEA;EACI;IACI,YAAA;EAAN;AACF","sourcesContent":[".docContainer {\n    position: relative;\n    display: flex;\n    overflow-y: auto;\n    overflow-x: hidden;\n    flex-direction: column;\n    border-left: 1px solid #d5deec;\n    height: calc(100vh - 57px);\n    padding-right: 37px;\n    flex: 1;\n    padding-bottom: 33px;\n}\n\n.docContainerHeadline,\n.docContainerHeadlineDoc {\n    padding-top: 49px;\n    font-family: \"NunitoSans-ExtraBold\";\n    font-size: 1.5rem;\n    line-height: 135%;\n    color: #415575;\n}\n\n.docContainerHeadlineDoc {\n    margin-left: 37px;\n}\n\n.docContainerBorder {\n    position: fixed;\n    left: 1100px;\n    top: 53px;\n    bottom: 0;\n    border-right: 1px solid #d5deec;\n}\n\n.noteSectionWrapper{\n    border-bottom: none;\n    padding-block: 0;\n}\n\n.sandboxTitle,\n.sandboxSubtitle {\n    font-family: \"NunitoSans-Regular\";\n    font-size: 0.875rem;\n    color: #202a3b;\n    line-height: 150%;\n}\n\n.sandboxTitle {\n    margin-top: 16px;\n}\n\n.sandboxSubtitle {\n    margin-top: 12px;\n}\n\n@media (max-width: 1400px) {\n    .docContainerBorder {\n        left: 900px;\n    }\n}\n\n@media (max-width: 900px) {\n    .docContainer {\n        width: 100vw;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"docContainer": `style_docContainer__BvoUV`,
	"docContainerHeadline": `style_docContainerHeadline__aZyWL`,
	"docContainerHeadlineDoc": `style_docContainerHeadlineDoc__p1o5Z`,
	"docContainerBorder": `style_docContainerBorder__FWDEi`,
	"noteSectionWrapper": `style_noteSectionWrapper__P+YVI`,
	"sandboxTitle": `style_sandboxTitle__NFa3l`,
	"sandboxSubtitle": `style_sandboxSubtitle__da1rL`
};
export default ___CSS_LOADER_EXPORT___;
