// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_modalContainer__oz9xd {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(32, 31, 31, 0.6);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
}

.style_modal__Pm3cF {
  display: flex;
  justify-content: center;
  align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/components/Modal/style.module.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,uCAAA;EACA,MAAA;EACA,OAAA;EACA,WAAA;EACA,YAAA;EACA,UAAA;AACJ;;AAEA;EACI,aAAA;EACA,uBAAA;EACA,mBAAA;AACJ","sourcesContent":[".modalContainer{\n    position: fixed;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    background-color: rgba(32, 31, 31, 0.6);\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    z-index: 2;\n}\n\n.modal{\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalContainer": `style_modalContainer__oz9xd`,
	"modal": `style_modal__Pm3cF`
};
export default ___CSS_LOADER_EXPORT___;
