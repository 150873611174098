const filtersForm = (customFilters) => {
  return (
    customFilters || {
      year: '2024',
      filter_by: '',
      filter_value: '',
      sort_by: 'usda_clu_id',
      asc: 'true',
      start_from: '',
      limit: '5',
      incl_image: 'false'
    }
  )
}

export default filtersForm
