// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_appShell__45yBY {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.style_appBodyContainer__6yo9A {
  position: relative;
  flex: 1 1;
  display: flex;
}

.style_currentPageContainer__0vAAE {
  flex: 1 1;
  display: flex;
}

.style_docSideBarOpenContainer__5YNsy {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.6);
  left: 0;
  top: 0;
  width: 100vw;
  height: 100%;
  z-index: 1;
  opacity: 1;
  animation: style_fadeIn__XYRK4 0.3s linear;
}

@keyframes style_fadeIn__XYRK4 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/layout/AppShell/style.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;EACA,aAAA;AACJ;;AAEA;EACI,kBAAA;EACA,SAAA;EACA,aAAA;AACJ;;AAEA;EACI,SAAA;EACA,aAAA;AACJ;;AAEA;EACI,kBAAA;EACA,oCAAA;EACA,OAAA;EACA,MAAA;EACA,YAAA;EACA,YAAA;EACA,UAAA;EACA,UAAA;EACA,0CAAA;AACJ;;AAEA;EACI;IACI,UAAA;EACN;EAEE;IACI,UAAA;EAAN;AACF","sourcesContent":[".appShell {\n    display: flex;\n    flex-direction: column;\n    height: 100vh;\n}\n\n.appBodyContainer {\n    position: relative;\n    flex: 1;\n    display: flex;\n}\n\n.currentPageContainer {\n    flex: 1;\n    display: flex;\n}\n\n.docSideBarOpenContainer{\n    position: absolute;\n    background-color: rgba($color: #000000, $alpha: 0.6);\n    left: 0;\n    top: 0;\n    width: 100vw;\n    height: 100%;\n    z-index: 1;\n    opacity: 1;\n    animation: fadeIn 0.3s linear;\n}\n\n@keyframes fadeIn{\n    from{\n        opacity: 0;\n    }\n\n    to{\n        opacity: 1;\n    }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"appShell": `style_appShell__45yBY`,
	"appBodyContainer": `style_appBodyContainer__6yo9A`,
	"currentPageContainer": `style_currentPageContainer__0vAAE`,
	"docSideBarOpenContainer": `style_docSideBarOpenContainer__5YNsy`,
	"fadeIn": `style_fadeIn__XYRK4`
};
export default ___CSS_LOADER_EXPORT___;
