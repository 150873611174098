// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_contactUsContainer__syr3X {
  padding: 25px;
  background-color: #fff;
  max-width: 500px;
  border-radius: 16px;
}
.style_contactUsContainer__syr3X h3 {
  display: flex;
  justify-content: space-between;
  color: #415575;
  font-family: "NunitoSans-Bold";
}
.style_contactUsContainer__syr3X h3 img {
  cursor: pointer;
}
.style_contactUsContainer__syr3X h4 {
  font-size: 1.125rem;
  font-family: "NunitoSans-Regular";
  color: #182F4E;
  line-height: 25px;
}
.style_contactUsContainer__syr3X h4 a {
  color: #319CFF;
}

.style_contactUsHeadlineContainer__pRrTT {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
}
.style_contactUsHeadlineContainer__pRrTT h3 {
  font-family: "NunitoSans-Bold";
  font-size: 1.125rem;
  color: #415575;
}
.style_contactUsHeadlineContainer__pRrTT img {
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/components/SideBar/SideBarContent/ContactUs/style.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;EACA,gBAAA;EACA,mBAAA;AACJ;AAAI;EACI,aAAA;EACA,8BAAA;EACA,cAAA;EACA,8BAAA;AAER;AADQ;EACI,eAAA;AAGZ;AAAI;EACI,mBAAA;EACA,iCAAA;EACA,cAAA;EACA,iBAAA;AAER;AADQ;EACI,cAAA;AAGZ;;AAEA;EACI,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,kBAAA;AACJ;AAAI;EACI,8BAAA;EACA,mBAAA;EACA,cAAA;AAER;AAAI;EACI,eAAA;AAER","sourcesContent":[".contactUsContainer{\n    padding: 25px;\n    background-color: #fff;\n    max-width: 500px;\n    border-radius: 16px;\n    h3{\n        display: flex;\n        justify-content: space-between;\n        color: #415575;\n        font-family: 'NunitoSans-Bold';\n        img{\n            cursor: pointer;\n        }\n    }\n    h4{\n        font-size: 1.125rem;\n        font-family: 'NunitoSans-Regular';\n        color: #182F4E;\n        line-height: 25px;\n        a{\n            color: #319CFF;\n        }\n    }\n}\n\n.contactUsHeadlineContainer{\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    margin-bottom: 5px;\n    h3{\n        font-family: 'NunitoSans-Bold';\n        font-size: 1.125rem;\n        color: #415575;\n    }\n    img{\n        cursor: pointer;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"contactUsContainer": `style_contactUsContainer__syr3X`,
	"contactUsHeadlineContainer": `style_contactUsHeadlineContainer__pRrTT`
};
export default ___CSS_LOADER_EXPORT___;
