// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../../public/media/magnifying-glass-icon.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_sideBarContentContainer__JVcIE {
  background-color: #fff;
  width: 340px;
  height: 300px;
  margin-left: 15px;
}

.style_searchIssueContainer__Mx9Zb {
  width: 100%;
}
.style_searchIssueContainer__Mx9Zb input {
  width: 100%;
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-repeat: no-repeat;
  background-position: left center;
  background-position-x: 5px;
  padding-block: 9px;
  padding-left: 30px;
  border: 1px solid #a5b5cc;
  border-radius: 8px;
}
.style_searchIssueContainer__Mx9Zb input:focus {
  border: none;
}`, "",{"version":3,"sources":["webpack://./src/components/SideBar/SideBarContent/style.module.scss"],"names":[],"mappings":"AAAA;EACI,sBAAA;EACA,YAAA;EACA,aAAA;EACA,iBAAA;AACJ;;AAEA;EACI,WAAA;AACJ;AAAI;EACI,WAAA;EACA,yDAAA;EACA,4BAAA;EACA,gCAAA;EACA,0BAAA;EACA,kBAAA;EACA,kBAAA;EACA,yBAAA;EACA,kBAAA;AAER;AADQ;EACI,YAAA;AAGZ","sourcesContent":[".sideBarContentContainer {\n    background-color: #fff;\n    width: 340px;\n    height: 300px;\n    margin-left: 15px;\n}\n\n.searchIssueContainer {\n    width: 100%;\n    input {\n        width: 100%;\n        background-image: url(\"../../../../public/media/magnifying-glass-icon.svg\");\n        background-repeat: no-repeat;\n        background-position: left center;\n        background-position-x: 5px;\n        padding-block: 9px;\n        padding-left: 30px;\n        border: 1px solid #a5b5cc;\n        border-radius: 8px;\n        &:focus{\n            border: none;\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sideBarContentContainer": `style_sideBarContentContainer__JVcIE`,
	"searchIssueContainer": `style_searchIssueContainer__Mx9Zb`
};
export default ___CSS_LOADER_EXPORT___;
