// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_clipBoardContainer__XXhyl {
  position: absolute;
  z-index: 2;
  right: 0;
  top: 0;
  margin: 5px 15px 0px 0px;
}

.style_clipBoardCopiedMessage__ocufi {
  display: flex;
  align-items: center;
  font-family: "NunitoSans-Regular";
  font-size: 0.875rem;
  position: absolute;
  height: 25px;
  top: 0;
  left: 0;
  transform: translateX(-100%);
  padding: 5px;
  background-color: #fff;
  color: #000000;
}

.style_clipBoardClickToCopyMessage__BbYBe {
  font-family: "NunitoSans-Regular";
  font-size: 0.875rem;
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: #fff;
  padding: 5px;
  width: max-content;
  color: #000000;
  transform: translate(calc(-100% + 25px), 100%);
  animation: style_slideIn__g-lmb 200ms ease-in-out;
}

@keyframes style_slideIn__g-lmb {
  from {
    transform: translate(-200%, 100%);
    opacity: 0;
  }
  to {
    transform: translate(calc(-100% + 25px), 100%);
    opacity: 1;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/CopyToClipboard/style.module.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,UAAA;EACA,QAAA;EACA,MAAA;EACA,wBAAA;AACJ;;AACA;EACI,aAAA;EACA,mBAAA;EACA,iCAAA;EACA,mBAAA;EACA,kBAAA;EACA,YAAA;EACA,MAAA;EACA,OAAA;EACA,4BAAA;EACA,YAAA;EACA,sBAAA;EACA,cAAA;AAEJ;;AACA;EACI,iCAAA;EACA,mBAAA;EACA,kBAAA;EACA,SAAA;EACA,OAAA;EACA,sBAAA;EACA,YAAA;EACA,kBAAA;EACA,cAAA;EACA,8CAAA;EACA,iDAAA;AAEJ;;AACA;EACI;IACI,iCAAA;IACA,UAAA;EAEN;EACE;IACI,8CAAA;IACA,UAAA;EACN;AACF","sourcesContent":[".clipBoardContainer {\n    position: absolute;\n    z-index: 2;\n    right: 0;\n    top: 0;\n    margin: 5px 15px 0px 0px;\n}\n.clipBoardCopiedMessage {\n    display: flex;\n    align-items: center;\n    font-family: \"NunitoSans-Regular\";\n    font-size: 0.875rem;\n    position: absolute;\n    height: 25px;\n    top: 0;\n    left: 0;\n    transform: translateX(-100%);\n    padding: 5px;\n    background-color: #fff;\n    color: #000000;\n}\n\n.clipBoardClickToCopyMessage {\n    font-family: \"NunitoSans-Regular\";\n    font-size: 0.875rem;\n    position: absolute;\n    bottom: 0;\n    left: 0;\n    background-color: #fff;\n    padding: 5px;\n    width: max-content;\n    color: #000000;\n    transform: translate(calc(-100% + 25px), 100%);\n    animation: slideIn 200ms ease-in-out;\n}\n\n@keyframes slideIn {\n    from {\n        transform: translate(-200%, 100%);\n        opacity: 0;\n    }\n\n    to {\n        transform: translate(calc(-100% + 25px), 100%);\n        opacity: 1;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"clipBoardContainer": `style_clipBoardContainer__XXhyl`,
	"clipBoardCopiedMessage": `style_clipBoardCopiedMessage__ocufi`,
	"clipBoardClickToCopyMessage": `style_clipBoardClickToCopyMessage__BbYBe`,
	"slideIn": `style_slideIn__g-lmb`
};
export default ___CSS_LOADER_EXPORT___;
