// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_toastContainer__edz0v {
  position: absolute;
  display: flex;
  justify-content: space-between;
  border-radius: 16px;
  padding: 5px;
  margin: 10px;
  right: 0;
  bottom: 0;
  z-index: 4;
  background-color: #fff;
  box-shadow: 0 0 2px black;
}
.style_toastContainer__edz0v div:first-child {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
}
.style_toastContainer__edz0v div:last-child {
  display: flex;
  flex-direction: column;
  padding: 5px;
}
.style_toastContainer__edz0v div:last-child h2 {
  font-family: "NunitoSans-Bold";
  font-size: 1.125rem;
  margin-bottom: 5px;
}
.style_toastContainer__edz0v div:last-child h3 {
  font-family: "NunitoSans-Regular";
  font-size: 1rem;
}`, "",{"version":3,"sources":["webpack://./src/components/Toast/style.module.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,YAAA;EACA,YAAA;EACA,QAAA;EACA,SAAA;EACA,UAAA;EACA,sBAAA;EACA,yBAAA;AACJ;AAAI;EACI,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,YAAA;AAER;AAAI;EACI,aAAA;EACA,sBAAA;EACA,YAAA;AAER;AADQ;EACI,8BAAA;EACA,mBAAA;EACA,kBAAA;AAGZ;AADQ;EACI,iCAAA;EACA,eAAA;AAGZ","sourcesContent":[".toastContainer {\n    position: absolute;\n    display: flex;\n    justify-content: space-between;\n    border-radius: 16px;\n    padding: 5px;\n    margin: 10px;\n    right: 0;\n    bottom: 0;\n    z-index: 4;\n    background-color: #fff;\n    box-shadow: 0 0 2px black;\n    div:first-child {\n        display: flex;\n        align-items: center;\n        justify-content: center;\n        padding: 5px;\n    }\n    div:last-child {\n        display: flex;\n        flex-direction: column;\n        padding: 5px;\n        h2 {\n            font-family: \"NunitoSans-Bold\";\n            font-size: 1.125rem;\n            margin-bottom: 5px;\n        }\n        h3 {\n            font-family: \"NunitoSans-Regular\";\n            font-size: 1rem;\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"toastContainer": `style_toastContainer__edz0v`
};
export default ___CSS_LOADER_EXPORT___;
